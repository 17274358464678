/**
 * JS styler
 */
const s = (k, v) => { //s for style. Easier to type
    return element => element.style[k] = v;
};

const apply_style = (html_element, ...args) => {
    args.forEach(fn => fn(html_element));
    return html_element;
};

/**
 * Tunic creates a styling function. You pass in arrays with the first key being
 * prop and the second being the value.
 * tunic([prop, value], [prop,value])(element)
 */
export const tunic = (...args) => {
    const style_fns = args.map(input => {
        return s(...input);
    });
    return (element) => {
        apply_style(
            element,
            ...style_fns
        )
    };
}
export const tunic_styles = {
    full_viewport: tunic(
        ['position', 'fixed'],
        ['height', '100%'],
        ['width', '100%'],
        ['left', '0'],
        ['top', '0']
    )
}
